import styled from "styled-components";

import webIcon from "../images/icons/Social/web.svg"
import twitterIcon from "../images/icons/Social/twitter.svg"
import discordIcon from "../images/icons/Social/discord.svg"
import instagramIcon from "../images/icons/Social/instagram.svg"
import Arrow from "../images/icons/Arrow/arrow-back.svg"

import {useLocation, useNavigate} from "react-router";
import {useEffect, useState} from "react";
import {getUserLandsByMetaverseName} from "../api/getUserLandsByMetaverseName";
import config from "../config";
import {getMetaversesInfo} from "../api/getMetaversesInfo";
import {getUserStatsBySlug} from "../api/getUserStatsBySlug";
import {numberFixed} from "../helpers/numberFixed";


const Back = styled.div`
  position: absolute;
  top: 26px;
  left: 100px;
  color: #fff;
  z-index: 3;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: "CeraPro", sans-serif;
  
  & > p {
    font-size: 16px;
    font-weight: 500;
  }
  
  & > img{
    margin-right: 16px;
  }

  @media screen and (max-width: 1090px){
    left: 20px;
  }
`;


const Info = styled.div`
  position: relative;
`;

const MetaHeared = styled.div`
  width: 100%;
  height: 300px;
  position: relative;
  top: 0;
  
  & > img {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 1090px){
    display: none;
  }
`;

const InfoPanel = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 210px;
  left: 70px;
  
  @media screen and (max-width: 1090px){
    position: initial;
    justify-content: center;
  }
`;

const NameContainer = styled.div`
  display: flex;
  align-items: flex-end;

  @media screen and (max-width: 1090px){
    display: block;
    margin-bottom: 20px;
  }
`;

const Square = styled.div`
  width: 200px;
  height: 200px;
  background: #2B1647;
  margin-right: 20px;
  
  & > img {
    padding: 18px;
    width: 164px;
    height: 164px;

    @media screen and (max-width: 1090px){
      padding: 8px;
    }
  }

  @media screen and (max-width: 1090px){
    width: 180px;
    height: 180px;
    margin: 80px auto;
  }
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  
  & > h2 {
    text-transform: uppercase;
    font-size: 24px;
    color: #fff;
    font-weight: 400;
    margin-bottom: 15px;
    font-family: "Agenor", sans-serif;

    @media screen and (max-width: 1090px){
     text-align: center;
    }
  }
  
`;

const Social = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: end;
    & > img {
      margin-right: 20px;
      cursor: pointer;
    }
`;

const OpenSeaLink = styled.div`
    color: #EA55FE;
  font-size: 16px;
  cursor: pointer;
  padding-bottom: 1px;
  border-bottom: 1px solid #EA55FE;
  position: absolute;
  bottom: 16px;
  right: 16px;
  font-family: "CeraPro", sans-serif;
  font-weight: 700;
`;

const InfoBlocks = styled.div`
    display: flex;
    align-items: flex-end;
    position: absolute;
    right: 70px;
    top: 330px;

  @media screen and (max-width: 1090px){
    position: initial;
    justify-content: center;
  }
`;

const BlockInfoItem = styled.div`
  width: 160px;
  height: 34px;
  background: rgba(217, 217, 217, 0.063);
  backdrop-filter: blur(31.7633px);
  margin: 8px;
  color: #fff;
  font-size: 24px;
  font-weight: 900;
  text-align: center;
  padding: 8px 0 28px;
  font-family: "CeraPro", sans-serif;
  
  & > p{
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
  }
`;

const UserAssets = styled.div`
    margin: 160px 70px 0 70px;
    display: flex;
    flex-wrap: wrap;  
    justify-content: space-between;

  @media screen and (max-width: 1090px){
    justify-content: center;
    margin: 50px 70px 0 70px;
  }
`;

const LandItem = styled.div`
  width: 310px;
  height: 277px;
  background: rgba(217, 217, 217, 0.063);
  backdrop-filter: blur(31.7633px);
  margin-bottom: 30px;
  position: relative;
  
  
  & > img {
    width: 100%;
    height: 220px;
    cursor: pointer;
  }
  
  & > p{
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    padding: 16px;
    font-family: "CeraPro", sans-serif;
  }

`;

function MetaverseInfo() {
    const [metaverses, setMetaverses] = useState([])
    const [metaversesInfo, setMetaversesInfo] = useState([])
    const [currentMetaverse, setCurrentMetaverse] = useState([])
    const [currentMetaverseStats, setCurrentMetaverseStats] = useState([])
    const [loading, setLoading] = useState(true)

    const location = useLocation()
    const navigate = useNavigate()

    const back = () => {
        navigate('/')
    }

    const getSlugId = () => {
        return location.pathname.split('/')[1]
    }

    useEffect(() => {
        let current = metaversesInfo.filter((item) => item.id === getSlugId())
        setCurrentMetaverse(current)
    },[metaversesInfo])

    useEffect(() => {
            getUserLandsByMetaverseName(config.TEST_USER_WALLET, getSlugId())
                .then((res) => {
                    setMetaverses(res)
                    setLoading(false)
                })
    }, [])

    useEffect(() => {
            getMetaversesInfo().then((res) => {
                setMetaversesInfo(res)
            })
    }, [])

    useEffect(() => {
            getUserStatsBySlug(config.TEST_USER_WALLET, getSlugId()).then((res) => {
                setCurrentMetaverseStats(res)
            })
    }, [])

    const externalCall = (link) => {
        window.open(`${link}`, '_blank');
    }

    const goOpenSea = (link) => {
        window.open(`${link}`, '_blank');
    }


    return (
                <Info>
                    <Back onClick={back}>
                        <img src={Arrow} alt=""/>
                        <p>back to portfolio</p>
                    </Back>
                    <MetaHeared>
                        <img src={currentMetaverse[0]?.background} alt=""/>
                    </MetaHeared>
                    <InfoPanel>
                        <NameContainer>
                            <Square>
                                <img src={currentMetaverse[0]?.icon} alt=""/>
                            </Square>
                            <Block>
                                <h2>{metaverses[0]?.metaverseId}</h2>
                                <Social>
                                    <a href={`${currentMetaverse[0]?.references[0]?.url}`}><img src={webIcon} alt=""/></a>
                                    <a href={`${currentMetaverse[0]?.references[3]?.url}`}><img src={twitterIcon} alt=""/></a>
                                    <a href={`${currentMetaverse[0]?.references[2]?.url}`}><img src={discordIcon} alt=""/></a>
                                    <a href={`${currentMetaverse[0]?.references[4]?.url}`}><img src={instagramIcon} alt=""/></a>
                                </Social>
                            </Block>
                        </NameContainer>
                    </InfoPanel>
                    <InfoBlocks>
                        <BlockInfoItem>{+currentMetaverseStats?.countLands}
                            <p>Lands</p>
                        </BlockInfoItem>

                        <BlockInfoItem>{numberFixed(currentMetaverseStats?.floorPrice, 2)}
                            <p>Floor Price</p>
                        </BlockInfoItem>

                        <BlockInfoItem>{numberFixed(currentMetaverseStats?.totalVolume, 2)}
                            <p>Volume</p>
                        </BlockInfoItem>

                    </InfoBlocks>
                    <UserAssets>
                        {metaverses.map((item, index) => {
                            return (
                                <LandItem key={index} onClick={() => externalCall(item.externalUrl)}>
                                    <img src={item.imageUrl} alt=""/>
                                    <p>{item.landName}</p>
                                    <OpenSeaLink onClick={() => goOpenSea(item.openseaUrl)}>
                                        More
                                    </OpenSeaLink>
                                </LandItem>
                            )
                        })}
                    </UserAssets>
                </Info>
    );
}

export default MetaverseInfo;
