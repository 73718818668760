import Header from "./Header/Header";
import styled from "styled-components";
import {useEffect, useState} from "react";
import config from "../config"
import {postUpdateLands} from "../api/postUpdateLands";
import {getUserStats} from "../api/getUserStats";
import StatisticMetaverseItem from "./StatisticMetaverseItem";
import GreenArrowIcon from "../images/icons/green-arrow/arrow.svg";
import News from "./News";
import TopSales from "./TopSales";
import React from 'react';
import Loader from "./Loader/Loader";
import {numberFixed} from "../helpers/numberFixed";
import DountChart from "./Chart/DountChart";


const Portfolio = styled.div`
  margin: 0 70px;

  @media screen and (max-width: 475px){
    margin: 0 20px;
  }
`;

const StatisticContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
  margin-top: 50px;
    margin-bottom: 80px;

  @media screen and (max-width: 1490px){
    display: block;
  }
`;

const Statistic = styled.div`
  width: 30%;
  
  @media screen and (max-width: 1490px){
    width: 100%;
  }
`;

const Diagram = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 350px;
  height: 350px;
  margin: 0 auto 36px auto;
`;

const TotalEth = styled.p`
  color: #fff;
  font-size: 34px;
  font-weight: 700;
  margin-bottom: 4px;
  font-family: "Cera Pro", sans-serif;
  
  & > span {
    font-size: 14px;
    font-weight: 700;
    color: #5EFF5A;
    margin-left: 8px;
    
    & > img{
      margin-right: 3px;
    }
  }
`;

const TotalInventory = styled.div`
  text-align: center;
  
  & > h3{
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 700;
    font-family: "Cera Pro", sans-serif;
  }
  
`;

const StatisticMetaverses = styled.div`
  width: 65%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (max-width: 1490px){
    margin-top: 30px;
    width: 100%;
  }

  @media screen and (max-width: 1400px){
    justify-content: space-evenly;
  }

  @media screen and (max-width: 980px){
    justify-content: center;

  }
`;

const NoPNLData = styled.div`
  color: #a3a7a7;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1070px){
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
`;

function PortfolioPage() {
    const [userLandsStats, setUserLandsStats] = useState([])
    const [totalInventoryValue, setTotalInventoryValue] = useState(0)
    const [totalInventoryPNL, setTotalInventoryPNL] = useState(0)
    const [loading, setLoading] = useState(true)
    const [bgColor, setBgColor] = useState([
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
    ])


    useEffect(() => {
            postUpdateLands(config.TEST_USER_WALLET)
    }, [])

    useEffect(() => {
            getUserStats(config.TEST_USER_WALLET).then((res) => {
                setUserLandsStats(res)
                setLoading(false)
            })
    }, [])



    const data = [
        {value: totalInventoryValue / userLandsStats[0]?.totalVolume, color: bgColor[0]},
        {value: totalInventoryValue / userLandsStats[1]?.totalVolume, color: bgColor[1]},
        {value: totalInventoryValue / userLandsStats[3]?.totalVolume, color: bgColor[3]},
    ]

    // TODO

    // useEffect(() => {
    //     const data = []
    //         for (let i = 0; i < userLandsStats.length; i++) {
    //             if (userLandsStats.length && userLandsStats[i].value > 0) {
    //                 let obj = {value: totalInventoryValue / userLandsStats[i].totalVolume, color: bgColor[i]}
    //                 if (obj.value !== Infinity && !isNaN(obj.value)) {
    //                     data.push(obj)
    //                 }
    //                 console.log(data)
    //             }
    //         }
    //     setData(data)
    //
    // }, [userLandsStats])


    useEffect(() => {
        const sum = userLandsStats.reduce((accumulator, object) => {
            return accumulator + object.totalVolume;
            }, 0);

        setTotalInventoryValue(sum);
    }, [userLandsStats])


    useEffect(() => {
        const sumPNL = userLandsStats.reduce((accumulator, object) => {
            return accumulator + object.currentPNL;
        }, 0);

        setTotalInventoryPNL(sumPNL);
    }, [userLandsStats])



    return (
        <Portfolio>
            <Header pageTitle={'My portfolio'} goBack={true} />
            <StatisticContainer>
                <Statistic>
                    <Diagram>
                        {userLandsStats[0]?.totalVolume !== 0 && data[0]?.value ?
                             <DountChart data={data} /> :
                            <NoPNLData><Loader isLoading={loading} />No PNL data</NoPNLData>
                        }
                    </Diagram>
                    <TotalInventory>
                        <h3>Total inventory value</h3>
                        <TotalEth>{numberFixed(totalInventoryValue, 6)} ETH
                            <span>
                            <img src={GreenArrowIcon} alt=""/>
                                {numberFixed(totalInventoryPNL, 3)}%
                            </span>
                        </TotalEth>
                        <p>Compared to $21,490 last year</p>
                    </TotalInventory>
                </Statistic>
                <StatisticMetaverses>
                    {userLandsStats?.map((item, index) => {
                        return <StatisticMetaverseItem
                            key={item.metaverseId}
                            metaverseId={item.metaverseId}
                            totalVolume={item.totalVolume}
                            countLands={item.countLands}
                            currentPNL={item.currentPNL}
                            color={bgColor[index]}
                        />
                    })}
                </StatisticMetaverses>
            </StatisticContainer>
            <Footer>
               <TopSales/>
               <News/>
            </Footer>
        </Portfolio>
    );
}

export default PortfolioPage;
