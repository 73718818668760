import styled from "styled-components";


const NewsItem = styled.div`
  position: relative;
  display: flex;
  max-width: 638px;
  min-height: 107px;
  margin-bottom: 20px;

  @media screen and (max-width: 768px){
    flex-direction: column;
    align-items: center;
  }
`;

const NewsImg = styled.div`
  width: 20%;
  height: 106px;
  margin-right: 24px;
  
  & > img {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 768px){
    width: auto;
    margin:0 0 20px 0;
  }
`;

const NewsContant = styled.div`
  width: 80%;
  display: block;
  padding: 0 10px 20px 10px;
  font-weight: 400;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const NewsTitle = styled.h3`
    text-align: justify;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-family: "Agenor", serif;
  line-height: 20px;
`;

const NewsText = styled.p`
  text-align: justify;
    color: #E3E4E6;
  font-size: 14px;
  font-weight: 400;
  font-family: "CeraPro", serif;
  line-height: 20px;
`;

const WebLink = styled.p`
  position: absolute;
  bottom: 0;
  right: 0;
  color: #EA55FE;
  font-size: 14px;
  cursor: pointer;
  font-family: "CeraPro", serif;
  font-weight: 700;
  border-bottom: 1px solid;

  @media screen and (max-width: 768px){
    position: initial;
    margin-bottom: 50px;
  }
`;

function NewsItemComponent({text, title, link, img}) {
    const visitWeb = (link) => {
        window.open(`${link}`, '_blank');
    }

    return (
                <NewsItem>
                    <NewsImg>
                        <img src={img} alt=""/>
                    </NewsImg>
                    <NewsContant>
                        <NewsTitle>{title}</NewsTitle>
                        <NewsText>{text}</NewsText>
                    </NewsContant>
                    <WebLink onClick={() => visitWeb(link)}>Visit website</WebLink>
                </NewsItem>
    );
}

export default NewsItemComponent;
