import * as d3 from "d3";
import {numberFixed} from "../../helpers/numberFixed";

const drawChart = (element, data) => {
    const colors = [];

    for (let i = 0; i < data.length; i++) {
        colors.push(data[i].color)
    }

    const boxSize = 500;

    d3.select(element).select("svg").remove(); // Remove the old svg
    // Create new svg
    const svg = d3
        .select(element)
        .append("svg")
        .attr("preserveAspectRatio", "xMidYMid meet")
        .attr("height", "100%")
        .attr("width", "100%")
        .attr("viewBox", `0 0 ${boxSize} ${boxSize}`)
        .append("g")
        .attr("transform", `translate(${boxSize / 2}, ${boxSize / 2})`);



    // const maxValue = data.reduce((cur, val) => Math.max(cur, val.value), 0);
    const arcGenerator = d3.arc().innerRadius(100).outerRadius(250);

    const pieGenerator = d3.pie().value((d) => d.value);

    const arcs = svg.selectAll().data(pieGenerator(data)).enter();
    arcs
        .append("path")
        .attr("d", arcGenerator)
        .style("fill", (d, i) => colors[i % data.length])
        .transition()
        .duration(700)
        .attrTween("d", function (d) {
            const i = d3.interpolate(d.startAngle, d.endAngle);
            return function (t) {
                d.endAngle = i(t);
                return arcGenerator(d);
            };
        });


    // Append text labels
    arcs
        .append("text")
        .attr("text-anchor", "middle")
        .text((d) => {
            if (d.data.value > 0) return `${numberFixed(d.data.value, 3)}%`
        }) // label text
        .style("fill", "#fff") // label color
        .style("font-size", "30px") // label size
        .attr("transform", (d) => {
            const [x, y] = arcGenerator.centroid(d);
            return `translate(${x}, ${y})`;
        })
        .style("font-size", 0)
        .transition()
        .duration(700)
        .style("font-size", "23px");

    // Add inner border
    svg
        .append("circle")
        .attr("cx", 0)
        .attr("cy", 0)
        .attr("r", 100) // should be same as innerRadius value
        .attr("stroke", "#8E5CEA")
        .attr("fill", "transparent")
        .transition()
        .duration(700)
        .attr("stroke-width", 26);

};

export default drawChart;