import styled from "styled-components";
import {numberFixed} from "../helpers/numberFixed";


const SalesItem = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  min-height: 107px;
  margin-bottom: 20px;
  
`;

const SalesImg = styled.div`
  display: flex;
  width: 95px;
  height: 106px;
  margin-right: 48px;
  
  & > img {
    //width: 100%;
    height: 100%;
  }
  
  & > span {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    margin-right: 12px;
  }
`;

const SalesContant = styled.div`
  display: block;
  padding: 10px;
`;

const SalesTitle = styled.h3`
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 8px;
      font-family: "Agenor", serif;
      font-weight: 400;
`;

const SalesContentFlex = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 1320px){
    flex-direction: column;
  }
`;

const PriceList = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Agenor", serif;
  font-weight: 400;
  line-height: 19px;
`;


const SalesText = styled.p`
  color: #E3E4E6;
  font-size: 14px;
  font-family: "CeraPro", serif;
  font-weight: 400;
`;

const PriceUSD = styled.p`
  color: #92FFA2;
  font-size: 14px;
  font-weight: 400;
`;

const PriceETH = styled.p`
  color: #92FFA2;
  font-size: 14px;
  font-weight: 400;
`;

function SalesItemComponent({id, name, img, collection, priceUSD, totalValue}) {
    
    return (
        <SalesItem>
            <SalesImg>
                <span>{id}.</span>
                <img src={img} alt=""/>
            </SalesImg>
            <SalesContentFlex>
                <SalesContant>
                    <SalesTitle>{name}</SalesTitle>
                    <SalesText>{collection}</SalesText>
                </SalesContant>
                <PriceList>
                    <PriceETH>{numberFixed(priceUSD * (totalValue / Math.pow(10, 15)), 1)} $</PriceETH>
                    <PriceUSD>{numberFixed(totalValue / Math.pow(10, 15), 1)} ETH</PriceUSD>
                </PriceList>

            </SalesContentFlex>
        </SalesItem>
    );
}

export default SalesItemComponent;
