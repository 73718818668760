import styled from "styled-components";
import NewsItemComponent from "./NewsItemComponent";
import {useEffect, useState} from "react";
import {getNews} from "../api/getNews";

const NewsContainer = styled.div`
width: 640px;
  
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const Title = styled.h2`
  color: #D272FF;
  font-size: 19px;
  margin-bottom: 24px;
  text-transform: uppercase;
  font-family: "Agenor", serif;
  font-weight: 400;

  @media screen and (max-width: 768px){
    text-align: center;
  }
`;

const NewsList = styled.div``;



function News() {
    const [news, setNews] = useState([])

    useEffect(() => {
            getNews().then((res) => {
                setNews(res)
            })
    }, [])

    // console.log(news)

    return (
        <NewsContainer>
            <Title>
                Metaverse news
            </Title>
            <NewsList>
                {news.map((item) => {
                    return(
                        <NewsItemComponent
                            key={item.newsId}
                            title={item.title}
                            text={item.summary}
                            link={item.sourceUrl}
                            img={item.mediaUrl}
                        />)
                })}
            </NewsList>
        </NewsContainer>
    );
}

export default News;
