import ScaleLoader from "react-spinners/ScaleLoader";

const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "#a100ff",
};

function Loader({isLoading}) {
    return (
        <div className="sweet-loading">
            <ScaleLoader color={"#ffffff"} loading={isLoading} cssOverride={override} size={150} />
        </div>
    );
}

export default Loader;