export function isMetaMaskInstalled() {
    const {ethereum} = window;
    ethereum.isMetaMask = undefined;
    return Boolean(ethereum && ethereum.isMetaMask);
}

export function connect() {
    try {
        const {ethereum} = window;
        return ethereum.request({method: 'eth_requestAccounts'});
    } catch (error) {
        console.error(error);
    }
}

export async function getEthAddress() {
    try {
        const {ethereum} = window;
        if (ethereum.selectedAddress) {
            return ethereum.selectedAddress;
        } else {
            await connect();
            return ethereum.selectedAddress;
        }
    } catch (error) {
        console.error(error);
    }
}