import './App.css';
import {Route, Routes, Navigate} from 'react-router'
import PortfolioPage from "./components/PortfolioPage";
import MetaverseInfo from "./components/MetaverseInfo";

function App() {
  return (
    <div className="App">
        <Routes>
            <Route path="/" element={<PortfolioPage/>}/>
            {/*<Route path="/" element={<HomePage/>}/>*/}
            <Route path="/*" element={<MetaverseInfo/>}/>
            <Route path="*" element={<Navigate to="/" replace/>}/>
        </Routes>
    </div>
  );
}

export default App;
