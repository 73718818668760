import styled from "styled-components";
import {useEffect, useState} from "react";
import {connect, getEthAddress, isMetaMaskInstalled} from "../../metamask";
import {formatEthAddress} from "../../helpers/formatEthAddress";
import {WalletIcon} from "../../images/icons/Wallet";
import {useNavigate} from "react-router";
import Arrow from "../../images/icons/Arrow/arrow-back.svg"

const HeaderComponent = styled.div`
    display: flex;
    align-items: center;
    text-transform: uppercase;
    justify-content: space-between;
    color: #fff;
    margin-top: 30px;
    margin-bottom: 22px;
`;



const HeaderTitle = styled.h2`
    font-size: 19px;
    font-family: "Agenor", sans-serif;
  
       & > img{
         margin-right: 8px;
         cursor: pointer;
       }
  
  @media screen and (max-width: 500px){
    font-size: 14px;
  }
`;

const HeaderSignIn = styled.div`
  padding: 10px 50px;
  cursor: pointer;
  background: linear-gradient(94.43deg, #F258FF 2.82%, #260BE6 106.99%);
  border-radius: 176px;

  & > button {
    background: transparent;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    border: 0;
  }

  &:hover {
    background: linear-gradient(94.43deg, #ed6dfa 2.82%, #361fe3 106.99%);
  }
  
  @media screen and (max-width: 530px){
    padding: 5px 20px;
  }
`;

const HeaderAddress = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: "Agenor", serif;
  font-weight: 400;
  
  & > svg{
    margin-right: 8px;
  }
`;


function Header({pageTitle, goBack}) {
    let [address, setAddress] = useState('');
    const navigate = useNavigate()


    async function signInMetamask(e) {
        e.stopPropagation();
        if (!address) {
            const accounts = await connect();
            address = accounts[0];
        }
        setAddress(address);

        await connect()
        try {
            if (isMetaMaskInstalled()) {
                let address = await getEthAddress();
                if (!address) {
                    const accounts = await connect();
                    address = accounts[0];
                    console.log(accounts)
                }
                setAddress(address);
            } else {
                throw new Error('You need to install Metamask browser extension first.');
            }
        } catch (err) {
            console.error(err);
        }
        console.log(e)
    }

    useEffect(() => {
        getEthAddress().then((address) => {
            setAddress(formatEthAddress(address, 6, 4));
        }).catch(() => {
            navigate('/')
        })
    }, []);

    const goToMyPortfolio = () => {
      navigate('/')
    }

    const goToHomePage = () => {
        navigate('/')
    }


    return (
            <HeaderComponent>
                <HeaderTitle className="header-title">
                    {goBack && <img src={Arrow} alt="" onClick={goToHomePage}/>}
                    {pageTitle}
                </HeaderTitle>
                {!address ?
                    <HeaderSignIn onClick={signInMetamask} className="header-signIn">
                        <button>Sign In</button>
                    </HeaderSignIn>
                    :
                    <HeaderAddress className="header-signIn" onClick={goToMyPortfolio}>
                        <WalletIcon/>
                        {formatEthAddress(address,5,4)}
                    </HeaderAddress>
                }

            </HeaderComponent>
    );
}

export default Header;
