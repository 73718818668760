import styled from "styled-components";
import {useEffect, useState} from "react";
import {getSales} from "../api/getSales";
import SalesItemComponent from './SalesItemComponent'

const SalesContainer = styled.div`
  max-width: 530px;
  @media screen and (max-width: 1320px){
    margin-bottom: 40px;
    width: 100%;
  }
`;
const Title = styled.h2`
  color: #D272FF;
  font-size: 19px;
  font-weight: 400;
  margin-bottom: 24px;
  text-transform: uppercase;
  font-family: "Agenor", serif;
`;

const SalesList = styled.div`
  width: 530px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;


function TopSales() {
    const [sales, setSales] = useState([])

    useEffect(() => {
            getSales().then((res) => {
                setSales(res)
            })
    }, [])

    return (
        <SalesContainer>
            <Title>
                top nft collectible sales (24h)
            </Title>
            <SalesList>
                {sales.map((item, index) => {
                    return(
                        <SalesItemComponent
                        id={index + 1}
                        key={index}
                        name={item.assetName}
                        img={item.imageUrl}
                        collection={item.collectionSlug}
                        priceUSD={item.paymentToken.usdPrice}
                        totalValue={item.totalPrice}
                        />
                    )
                })}
            </SalesList>
        </SalesContainer>
    );
}

export default TopSales;
