import styled from "styled-components";
import {Link} from "react-router-dom";
import {numberFixed} from "../helpers/numberFixed";
import GreenArrowIcon from "../images/icons/green-arrow/arrow.svg"

const ItemContainer = styled.div`
  width: 374px;
  height: 100px;
  padding: 20px 23px;
  background: rgba(217, 217, 217, 0.063);
  backdrop-filter: blur(31.7633px);
  margin-bottom: 20px;
  position: relative;
  font-weight: 700;
  font-family: "CeraPro", sans-serif;
`;

const Title = styled.h3`
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 4px;
  text-transform: uppercase;
`;

const AmountEth = styled.p`
  color: #fff;
  font-size: 34px;
  font-weight: 700;
  margin-bottom: 4px;
  
  & > span {
    font-size: 14px;
    font-weight: 700;
    color: #5EFF5A;
    
    & > img{
      margin-right: 3px;
    }
  }
`;

const SubTitle = styled.p`
  color: #fff;
  font-size: 16px;
  font-weight: 400;
`;

const ColorBlock = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin: 0 16px 0 0;
`;

const ShowMore = styled.button`
  position: absolute;
  bottom: 20px;
  right: 23px;
  color: #EA55FE;
  cursor: pointer;
  padding-bottom: 1px;
  border-bottom: 1px solid #EA55FE;
  background: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
`;

const ContantFlex = styled.div`
  display: flex;
  align-items: center;
`;

const InfoFlex = styled.div`
  display: flex;
  flex-direction: column;
`;




function StatisticMetaverseItem({metaverseId, totalVolume, countLands, currentPNL, color}) {
  return (

          <ItemContainer>
              <ContantFlex>
                  <ColorBlock style={{background: color}}></ColorBlock>
                  <InfoFlex>
                      <Title>{metaverseId}</Title>
                      <AmountEth>{numberFixed(totalVolume, 2)} ETH <span><img src={GreenArrowIcon} alt=""/>{numberFixed(currentPNL, 1)}%</span></AmountEth>
                      <SubTitle>{countLands} lands</SubTitle>
                  </InfoFlex>
              </ContantFlex>

              <Link to={metaverseId}>
                  <ShowMore disabled={!countLands}>Show more</ShowMore>
              </Link>
          </ItemContainer>
  )
}

export default StatisticMetaverseItem;
